.AccuntDropdownButton {
    color: #dfcaa3;
    background-color: #7f0000;
    border: none;
}

.AccuntDropdownButton:hover {
    color: #ffab3d;
}

.NavHedar {
    /* width: 100%; */
    /* min-height: 165px; */
    background-color: white;
    /* position: fixed; */
    z-index: 5;
    box-shadow: rgb(163, 163, 162) -18px -20px 15px 15px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.NavLinkHedar {
    color: #dfcaa3;
    text-decoration: none;
    font-weight: 700;
    /* margin: 0 10px; */
    position: relative;
    transition: color 0.3s;
}

.MenuNavLink .active {
    color: white;
    /* border-bottom: 2px #842cff solid; */
}