.AllPage {
    display: flex;
    justify-content: center;
}

.Page {
    width: 100%;
    max-width: 1440px;
}

.cardHome {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
}

.cardHome Img {
    width: 300px;
    height: 300px;
}

.imgHomeHover2 {
    width: 300px;
    height: 300px;
    transition: all linear 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.cardHome:hover img {
    opacity: 0;
}

.cardHome:hover img.imgHomeHover2 {
    opacity: 1;
}

.nameOffer {
    color: white;
    position: absolute;
    z-index: 2;
    top: 40vh;
    height: 100px;
    width: 100%;
    /* justify-content: center;
    align-items: center; */
    text-align: center;
    animation: movslider 7s infinite;
}

.BrandShoppBrand {
    font-size: 20px;
    height: 35px;
    transition: border 0.5s;
    padding: 0 20px;
    border: 1px solid white;
    border-radius: 10px;
    color: initial;
    text-decoration: none;
}

.BrandShoppBrand:hover {
    border: 1px solid;
    color: #ffab3d;
}

.ImgModal {
    max-width: 100%;
    min-width: 100%;
    max-height: 15vh;
    min-height: 15vh;
    padding: 5px;
    transition: 0.3s;
}

.ImgModal:hover {
    transform: scale(1.1);
}

/* .image-container {
    display: flex;
    width: 200%; 
    animation: movslider 10s infinite;
}

.image-container img {
    width: 100%; 
    object-fit: cover;
} */
@keyframes fade {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}







@keyframes movslider {
    0% {
        transform: translateY(100%);
        /* يبدأ من خارج الشاشة من جهة اليمين */
    }

    15% {
        transform: translateY(-50%);
        /* الكلمة في الوسط */
    }

    25% {
        transform: translateY(0);
        /* الكلمة في الوسط */
    }

    50% {
        transform: translateY(0);
        /* الكلمة في الوسط */
    }

    100% {
        transform: translateY(0);
        /* الكلمة تبقى في الوسط */
    }
}