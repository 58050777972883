.ItemMenue {
    padding: 10px 20px;
    width: 100%;
    text-align: start;
    cursor: pointer;
    font-size: 20px;
}

.ItemMenue:hover {
    background-color: rgba(127, 0, 0 0, 0.05);
}