.react-autosuggest__container {
    position: sticky;
    width: 100%;
    margin: 0 auto;
    top: 70px;
    border-radius: 20px;
}

.react-autosuggest__input {
    width: 100%;
    align-items: center;
    height: 40px;
    font-size: 13px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 5px 10px;
}

.react-autosuggest__suggestions-container {
    position: absolute;
    top: 40px;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-top: none;
    background-color: #fff;
    z-index: 2;
    overflow-y: auto;
    max-height: 300px;
    border-radius: 4px;
}

.react-autosuggest__suggestions-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: start;
}

.react-autosuggest__suggestion {
    width: 100%;
    padding: 5px;
    cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ececec;
}

.custom-pagination {
    padding: 0;
}