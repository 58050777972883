* {
  margin: 0;
  padding: 0;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.BtnBNB {
  background-color: #7f0000;
  border: none;
  color: white;
  border-radius: 7px;
  padding: 0 20px;
  height: 40px;
  bottom: 0;
  transition: 0.3s;
}

.BtnBNB:hover {
  background-color: #910000;
  transition: 0.3s;
}

.BtnBNB:active {
  background-color: #660000;
}

.BtnBNB:disabled {
  background-color: #d3d3d3;
  color: white;
  cursor: not-allowed;
  border: 1px solid #d3d3d3;
}

.BtnBNBIcon {
  background-color: white;
  border: none;
  transition: 0.5s;
}

.BtnBNBIcon:hover {
  color: #7f0000;
}

.BtnBNBIcon:active {
  color: #660000;
}

.BtnBNBIconFavorite {
  background-color: white;
  color: #7f0000;
  border: none;
  transition: 0.5s;
}

.BtnBNBIconFavorite:hover {
  color: #b10000;
}