@media print {
    body {
        -webkit-print-color-adjust: exact;
        /* للحفاظ على الألوان */
        color-adjust: exact;
        /* للحفاظ على الألوان */
    }

    /* إخفاء العناصر غير المرغوب في طبعها */
    .no-print {
        display: none !important;
    }

    /* إخفاء القائمة والفوتر فقط أثناء الطباعة */
    .HedarAdmin,
    /* افترض أن فئة القائمة هي navbar */
    .Footer,
    /* افترض أن فئة الفوتر هي footer */
    .OutletAdmin,
    /* إذا كان الآوتليت يحتوي على فئة outlet */
    .some-other-class {
        /* أضف هنا أي فئة أخرى قد تكون للقائمة أو الفوتر */
        display: none !important;
    }

    /* تنسيق الفاتورة للطباعة */
    .invoice-container {
        margin: 0;
        padding: 20px;
        width: 100%;
        box-shadow: none;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th,
    td {
        border: 1px solid #dddddd;
        padding: 8px;
    }

    th {
        background-color: black;
        color: white;
    }

    .invoice-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
    }

    .invoice-header div {
        flex-grow: 1;
        text-align: left;
    }

    .invoice-table {
        width: 100%;
    }

    .total {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
    }
}