.radio-container {
    display: flex;
    gap: 40px;
}

.radio-label {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    padding-left: 35px;
    user-select: none;
    color: #555;
    color: gray;
    font-weight: 600;
    transition: color 0.3s ease;
}

.radio-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-custom {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    background-color: #ddd;
    border-radius: 50%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.radio-label:hover input~.radio-custom {
    background-color: #ccc;
}

.radio-label input:checked~.radio-custom {
    background-color: #2196f3;
    box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.5);
}

.radio-custom:after {
    content: "";
    position: absolute;
    display: none;
}

html[dir="rtl"] .radio-label {
    flex-direction: row-reverse;
    text-align: right;
}

.radio-label input:checked~.radio-custom:after {
    display: block;
}

.radio-label .radio-custom:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
}