.NavHedar {
    /* width: 100%; */
    /* min-height: 165px; */
    background-color: white;
    /* position: fixed; */
    z-index: 5;
    box-shadow: rgb(163, 163, 162) -18px -20px 15px 15px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.NavLinkHedar {
    color: black;
    text-decoration: none;
    font-weight: 700;
    /* margin: 0 10px; */
    position: relative;
    transition: color 0.3s;
}

.MenuNavLink .active {
    color: #ff9100;
    /* border-bottom: 2px #842cff solid; */
}


.NavLinkHedar:hover {
    color: #ff9100;
}

.IconHedar {
    transition: 0.1s;
}

.IconHedar:hover {
    transform: scale(1.05);
}

.CloseBtn {
    display: flex;
    justify-content: space-between;
}

.CloseBtn .btn-close {
    margin: 0;
}


/* .BasicExample .collapsed{
    margin: 0;
} */

/* @media (prefers-reduced-motion: no-preference) {
    .NavLinkHedar {
        animation: hoverNavLink forwards 0.2s linear;
    }
} */


@keyframes hoverNavLink {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.07);
    }
}