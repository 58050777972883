.message {
    background-color: rgb(90, 90, 90);
    padding:10px 0.75rem;
    border-radius: 5px;
    max-width: 50%;
    color: white;
}


.message.self {
    background-color: blueviolet;
}