.floating-container {
    position: fixed;
    bottom: 30px;
    right: 35px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.floating-button {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    margin-bottom: 50px;
}

.icon-container.open {
    opacity: 1;
    visibility: visible;
}

.floating-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    transform: translateY(20px);
    opacity: 0;
}

.icon-container.open .floating-icon {
    transform: translateY(0);
    opacity: 1;
}